import appConfig from '@/app/configs/appConfig.json';
import { isProdMode } from '@app/helpers/helpers';
import { WalletName } from '@app/store/reducers/wallets';

/** Defines defualt values for tokens query pagination filters */
export const DEFAULT_TOKENS_LIMIT = 10;
export const DEFAULT_TOKENS_PAGE = 1;

/** Defines list of possible blockchain network interoperability types. */
export enum NetworkTypes {
    CASPER = 'NT_CASPER',
    CONCORDIUM = 'NT_CONCORDIUM',
    EVM = 'NT_EVM',
    STELLAR = 'NT_STELLAR',
    XVERSE = 'NT_BITCOIN',
}

/** Defines available networks */
export enum NetworkNames {
    CASPER_TEST = 'CASPER-TEST',
    CONCORDIUM = 'CONCORDIUM',
    CONCORDIUM_TEST = 'CONCORDIUM-TEST',
    SEPOLIA = 'SEPOLIA',
    ETHEREUM = 'ETHEREUM',
    POLYGON = 'POLYGON',
    MUMBAI = 'MUMBAI',
    BNB = 'BNB',
    BNB_TEST = 'BNB-TEST',
    AVALANCHE = 'AVALANCHE',
    AVALANCHE_TEST = 'AVALANCHE-TEST',
    PHOENIX = 'PHOENIX',
    PEGASUS = 'PEGASUS',
    STELLAR = 'STELLAR',
    STELLAR_TEST = 'STELLAR-TEST',
    XVERSE = 'RUNES',
    XVERSE_TEST = 'RUNES-TEST',
    CROSSFI = 'CROSSFI',
    CROSSFI_TEST = 'CROSSFI-TEST',
    RUNES = 'RUNES',
    RUNES_TEST = 'RUNES-TEST',
    RUNES_SIGNET = 'RUNES-SIGNET',
    BITCOIN = 'BITCOIN',
    BITCOIN_TEST = 'BITCOIN-TEST',
    BITCOIN_SIGNET = 'BITCOIN-SIGNET',
}

// TODO: delete after API implementation.
/** Defines all available EVM networks chains. */
export enum NetworksChains {
    SEPOLIA = '0xaa36a7',
    ETHEREUM = '0x1',
    POLYGON = '0x89',
    MUMBAI = '0x13881',
    BNB = '0x38',
    BNB_TEST = '0x61',
    AVALANCHE = '0xa86a',
    AVALANCHE_TEST = '0xA869',
    PHOENIX = '0x762',
    PEGASUS = '0x763',
    CROSSFI = '0x103e',
    CROSSFI_TEST = '0x103d',
}

/** Holds basic network characteristics. */
export class Network {
    constructor(
        public id: number,
        public name: NetworkNames = NetworkNames.SEPOLIA,
        public type: NetworkTypes = NetworkTypes.EVM,
        public bridgeContract: string = '',
        public gasLimit: string = '',
        public active: boolean = false,
        public iconLink: string = '',
        public explorerBaseUrl: string = '',
    ) {}
}

/** Holds information about supported by tricorn tokens. */
export class Token {
    constructor(
        public decimals: number = appConfig.numbers.ZERO_NUMBER,
        public id: number = appConfig.numbers.ZERO_NUMBER,
        public shortName: string = '',
        public longName: string = '',
        public smartContractAddress: string = '',
        public iconLink: string = '',
    ) {}
}

export class TokenResponse {
    constructor(
        public Tokens: Token[] = [],
        public TotalCount: number = appConfig.numbers.ZERO_NUMBER,
        public Limit: number = appConfig.numbers.ZERO_NUMBER,
        public Offset: number = appConfig.numbers.ZERO_NUMBER,
        public PageCount: number = appConfig.numbers.ZERO_NUMBER,
        public CurrentPage: number = appConfig.numbers.ZERO_NUMBER,
    ) { }
}

export class TokenSearch {
    constructor(
        public search: string,
        public limit: number = DEFAULT_TOKENS_LIMIT,
        public page: number = DEFAULT_TOKENS_PAGE,
    ) { }
}

export enum Networks {
    'CASPER',
    'EVM',
}

export class Coin {
    constructor(
        public name: string = '',
        public symbol: string = '',
        public image: string = '',
    ) {}
}

export const NETWORK_WALLET_MAP: {[key in NetworkTypes]: WalletName} = {
    [NetworkTypes.EVM]: WalletName.METAMASK,
    [NetworkTypes.CASPER]: WalletName.CASPER,
    [NetworkTypes.CONCORDIUM]: WalletName.CONCORDIUM,
    [NetworkTypes.STELLAR]: WalletName.FREIGHTER,
    [NetworkTypes.XVERSE]: WalletName.XVERSE,
};

export const targetBitcoinPaymentNetworkIds = [
    appConfig.numbers.TWENTY_SIX_NUMBER,
    appConfig.numbers.TWENTY_SEVEN_NUMBER,
    appConfig.numbers.TWENTY_EIGHT_NUMBER,
];

export const targetBitcoinRunestNetworkIds = [
    appConfig.numbers.NINETEEN_NUMBER,
    appConfig.numbers.TWENTY_NUMBER,
    appConfig.numbers.TWENTY_NINE_NUMBER,
];

export const incompatibleMetamaskNetworks = [
    NetworkNames.CASPER_TEST,
    NetworkNames.STELLAR,
    NetworkNames.STELLAR_TEST,
    NetworkNames.CONCORDIUM,
    NetworkNames.CONCORDIUM_TEST,
    NetworkNames.RUNES,
    NetworkNames.RUNES_TEST,
    NetworkNames.RUNES_SIGNET,
    NetworkNames.BITCOIN,
    NetworkNames.BITCOIN_TEST,
    NetworkNames.BITCOIN_SIGNET,
];

export const compatibleXverseNetworks = [
    NetworkNames.RUNES,
    NetworkNames.RUNES_TEST,
    NetworkNames.RUNES_SIGNET,
    NetworkNames.BITCOIN,
    NetworkNames.BITCOIN_TEST,
    NetworkNames.BITCOIN_SIGNET,
];
