import { NotificationsPlugin as Notifications } from '@app/plugins/notifications';
import appConfig from '@/app/configs/appConfig.json';
import { NETWORK_WALLET_MAP, Network, NetworkTypes } from '@/networks';
import { Wallet, WalletName } from '@app/store/reducers/wallets';
import BigNumber from 'bignumber.js';

export const getWalletAddressLabel = (address: string): string => address && `${address.slice(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.FOUR_NUMBER)}...${address.slice(
    appConfig.numbers.MINUS_THREE_NUMBER,
)}`;

export const getTxLabel = (tx: string): string => tx && `${tx.slice(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.SIX_NUMBER)}...${tx.slice(
    appConfig.numbers.MINUS_THREE_NUMBER,
)}`;

export const copyWalletAddress = (address: string, isShowNotification = true) => {
    navigator.clipboard.writeText(address);
    isShowNotification && Notifications.copied();
};

export const checkRequiredWalletConnected = (networkType: NetworkTypes, connectedWallets: Wallet[]) => {
    const isWalletConnected = (walletName: WalletName) => connectedWallets.findIndex(wallet => wallet.name === walletName) !== appConfig.numbers.EMPTY_FIND_INDEX_RESULT;
    if(isWalletConnected(NETWORK_WALLET_MAP[networkType])) {
        return true;
    }
    // Notify user to connect required wallet:
    switch(networkType) {
        case NetworkTypes.XVERSE:
            Notifications.xverseIsNotConnected();
            break;
        case NetworkTypes.EVM: Notifications.metamaskIsNotConnected();
            break;
        case NetworkTypes.CASPER: Notifications.casperIsNotConnected();
            break;
        case NetworkTypes.CONCORDIUM: Notifications.concordiumIsNotConnected();
            break;
        case NetworkTypes.STELLAR: Notifications.stellarIsNotConnected();
            break;
        default: break;
    }

    return false;
};

export const formatNumber = (num: string | number): string => {
    const maxDigits = appConfig.numbers.EIGHT_NUMBER;
    const value = num.toString();
    if (value.length <= maxDigits) {
        return value;
    }
    return `${value.slice(appConfig.numbers.ZERO_NUMBER, maxDigits)}...`;
};

export const getExplorerBaseUrl = (networkName: string, networks: Network[]) => {
    const network = networks.find((item) => item.name === networkName);
    return network ? network.explorerBaseUrl : '';
};
export const formatText = (text: string, maxChars: number): string => {
    if (text.length <= maxChars) {
        return text;
    }
    return `${text.slice(appConfig.numbers.ZERO_NUMBER, maxChars)}...`
}

export const isProdMode = import.meta.env.VITE_IS_PROD_MODE === 'true';

export const isBridgeTricorn = import.meta.env.VITE_IS_BRIDGE_TRICORN?.trim() !== 'false';
